import {Injectable} from '@angular/core';
import {BackofficeRoles} from '@common/enum/backofficeRoles.enum';
import {UserRoles} from '@common/statics/userRoles';
import {BackofficeURLs} from '@common/ts/backofficeFormInterfaces';
import {RouteCommon} from '@common/ts/routeCommon';

@Injectable()
export class MenuRoutesService extends RouteCommon<BackofficeURLs> {

  dashboardRoute(): void {
    if (UserRoles.hasRole([BackofficeRoles.ROLE_BO_COLLECTION_AGENT])) {
      this.menuRoutes('backoffice/collection/calllist');
    } else if (UserRoles.hasRole([BackofficeRoles.ROLE_BO_COLLECTION_LOGISTIC_EXTERNAL_PARTNER])) {
      this.menuRoutes('backoffice/collection/list');
    } else {
      this.menuRoutes('backoffice/dashboard');
    }
  }

}
