import {ImageSizeConfig} from '@common/enum/imageSizeConfig';
import {getFileSizeInMb} from '@common/statics/getFileSizeInMb';
import {MAX_FILE_SIZE} from '@common/ts/globals';
import {UploadFile} from '@common/ts/interfaces';
import {Observable} from 'rxjs';

export function imageValidation({type, size, nativeFile}: UploadFile): Observable<string> {
  return new Observable((obs) => {
    if (!type.includes('image') || type.includes('image/tiff')) {
      obs.next('Wrong file type. Only image files are allowed!');
      obs.complete();
    } else if (size > MAX_FILE_SIZE) {
      obs.next(`${getFileSizeInMb(size)}MB, but only ${getFileSizeInMb(MAX_FILE_SIZE)}MBs maximum size are allowed`);
      obs.complete();
    }
    const fileReader = new FileReader();
    fileReader.onload = (): void => {
      const img = new Image();
      img.onload = (): void => {
        obs.next(validateImageSize(img.naturalWidth, img.naturalHeight));
        obs.complete();
      };
      img.src = String(fileReader.result);
    };
    fileReader.readAsDataURL(nativeFile);
  });
}

export function validateImageSize(width: number, height: number): string {
  return width < ImageSizeConfig.minWidth || height < ImageSizeConfig.minHeight ?
    `${width}x${height}px, but minimum image width and height is 500px` : '';
}
