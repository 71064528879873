import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {setResponseMessage} from '@common/statics/setResponseMessage';
import {BACKEND_URL} from '@common/ts/config';
import {GridFilterDTO} from '@common/ts/interfaces';
import {EMPTY, Observable} from 'rxjs';

import texts from './filterService';

@Injectable()
export class FilterService {

  private serviceUrlPath = `${BACKEND_URL}/inventory/filter`;

  constructor(public http: HttpClient) {
  }

  saveGridData<T>(request: GridFilterDTO<T>): Observable<GridFilterDTO<T>> {
    return this.http.post<GridFilterDTO<T>>(`${this.serviceUrlPath}`, request,
      setResponseMessage({
        message: texts.filterSaveSuccess,
        errorMessage: texts.filterSaveFailed,
      }));
  }

  deleteFilter(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.serviceUrlPath}/${id}`, setResponseMessage({
      message: texts.filterDeleteSuccess,
      errorMessage: texts.filterDeleteFailed,
    }));
  }

  getAllFilterByName<T>(name: T, onlyCompanyWide = false): Observable<GridFilterDTO<T>[]> {
    return this.http.get<GridFilterDTO<T>[]>(`${this.serviceUrlPath}/list/${name}?onlyCompanyWide=${onlyCompanyWide}`);
  }

  filterSearch<T>(filterId: string): Observable<GridFilterDTO<T>> {
    return filterId !== null ? this.http.get<GridFilterDTO<T>>(`${this.serviceUrlPath}/${filterId}`) : EMPTY;
  }

}
