import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmComponent} from '@common/components/confirm/confirm.component';
import {ConfirmDialogDTO} from '@common/ts/interfaces';
import {Observable} from 'rxjs';

@Injectable()
export class ConfirmService {

  constructor(public dialog: MatDialog) {
  }

  confirm(value: string, customConfirmText = '', customCancelText = ''): Observable<boolean | undefined> {
    return this.dialog.open<ConfirmComponent, ConfirmDialogDTO, boolean>(ConfirmComponent, {
      data: {
        value,
        customConfirmText,
        customCancelText,
      },
    }).afterClosed();
  }

}
