import {HttpEventType} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, inject, OnInit, signal} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {HeroIconComponent} from '@common/components/hero-icon/hero-icon.component';
import imageUpload from '@common/json/imageUpload';
import {ImageUploadService} from '@common/services/image-upload/image-upload.service';
import {resizeImage} from '@common/statics/imageResize';
import {imageValidation} from '@common/statics/imageValidation';
import {ImageUploadBarDTO, ImageUploadRequestDTO, UploadFile} from '@common/ts/interfaces';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import texts from './imageUploadProgressBar';

@UntilDestroy()
@Component({
  selector: 'app-image-upload-progress-bar',
  templateUrl: './image-upload-progress-bar.component.html',
  styleUrl: './image-upload-progress-bar.component.scss',
  imports: [
    HeroIconComponent,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageUploadProgressBarComponent implements OnInit {

  public uploadPercent = signal(0);
  public uploading = signal(true);
  public errors = <string[]>[];
  public validImages = <UploadFile[]>[];
  public imageUploadProgressBarTexts = texts;
  public uploadLabel = signal(imageUpload.imageUploadProcess);
  public data = inject<ImageUploadBarDTO>(MAT_SNACK_BAR_DATA);

  constructor(public imageUploadSrv: ImageUploadService,
              public uploadProgressRef: MatSnackBarRef<ImageUploadProgressBarComponent>) {
    if (this.data.files) {
      for (const file of this.data.files) {
        imageValidation(file).pipe(untilDestroyed(this)).subscribe({
          next: (error) => {
            if (error.length) {
              this.errors.push(`${file.name}: ${error}`);
            } else {
              this.validImages.push(file);
            }
            if (this.validImages.length + this.errors.length === this.data.files.length) {
              this.processImages();
            }
          },
        });
      }
    }
  }

  uploadImages(processedImages: ImageUploadRequestDTO[]): void {
    this.imageUploadSrv.uploadImages(processedImages).pipe(untilDestroyed(this)).subscribe({
      next: (event) => {
        switch (event.type) {
          case HttpEventType.Sent:
            this.uploadLabel.set(imageUpload.imageUploadStarted);
            break;
          case HttpEventType.UploadProgress:
            this.uploadPercent.set(Math.round(100 * event.loaded / event.total));
            this.uploadLabel.set(`${this.uploadPercent()}${imageUpload.percentUploaded}`);
            break;
          case HttpEventType.Response:
            this.uploadLabel.set(imageUpload.uploadComplete);
            this.imageUploadSrv.imagesUploaded.emit(event.body);
            this.uploading.set(false);
            if (!this.errors.length) {
              this.uploadProgressRef.dismiss();
            }
            break;
        }
      }, error: () => {
        this.uploading.set(false);
      },
    });
  }

  processImages(): void {
    if (this.validImages.length) {
      let processFileCount = 0;
      const processedImages = <ImageUploadRequestDTO[]>[];
      for (const fileIndex in this.validImages) {
        if (this.validImages[fileIndex] !== undefined) {
          resizeImage(this.validImages[fileIndex].nativeFile).pipe(untilDestroyed(this)).subscribe({
            next: (resized) => {
              processedImages[fileIndex] = {
                imageName: this.validImages[fileIndex].name,
                imageData: resized.replace('data:image/jpeg;base64,', ''),
                tags: this.data.tags,
              };
              processFileCount++;
              if (processFileCount === this.validImages.length) {
                this.uploadImages(processedImages);
              }
            },
          });
        }
      }
    } else {
      this.uploading.set(false);
      this.imageUploadSrv.imagesUploaded.emit([]);
    }
  }

  ngOnInit(): void {
    if (this.data.processedFiles) {
      this.uploadImages(this.data.processedFiles);
    }
  }

}
