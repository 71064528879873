import {RelativeValues} from '@common/enum/relativeValueTypes.enum';
import {
  endOfDay,
  endOfISOWeek,
  endOfMonth,
  endOfYear,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';

export interface RelativeValueDTO {
  dateFrom: Date;
  dateTo: Date;
}

// eslint-disable-next-line sonar/cyclomatic-complexity,max-lines-per-function
export function getRelativeDates(relativeValue: RelativeValues): RelativeValueDTO {
  const now = new Date();
  switch (relativeValue) {
    case RelativeValues.Today:
      return {
        dateFrom: startOfDay(now),
        dateTo: endOfDay(now),
      };
    case RelativeValues.Yesterday:
      return {
        dateFrom: startOfDay(subDays(now, 1)),
        dateTo: endOfDay(subDays(now, 1)),
      };
    case RelativeValues.ThisWeek:
      return {
        dateFrom: startOfDay(startOfISOWeek(now)),
        dateTo: endOfDay(endOfISOWeek(now)),
      };
    case RelativeValues.LastWeek:
      return {
        dateFrom: startOfDay(startOfISOWeek(subWeeks(now, 1))),
        dateTo: endOfDay(endOfISOWeek(subWeeks(now, 1))),
      };
    case RelativeValues.ThisMonth:
      return {
        dateFrom: startOfDay(startOfMonth(now)),
        dateTo: endOfDay(endOfMonth(now)),
      };
    case RelativeValues.LastMonth:
      return {
        dateFrom: startOfDay(startOfMonth(subMonths(now, 1))),
        dateTo: endOfDay(endOfMonth(subMonths(now, 1))),
      };
    case RelativeValues.Last7Day:
      return {
        dateFrom: startOfDay(subDays(now, 7)),
        dateTo: endOfDay(now),
      };
    case RelativeValues.Last30Day:
      return {
        dateFrom: startOfDay(subDays(now, 30)),
        dateTo: endOfDay(now),
      };
    case RelativeValues.Last60Day:
      return {
        dateFrom: startOfDay(subDays(now, 60)),
        dateTo: endOfDay(now),
      };
    case RelativeValues.Last90Day:
      return {
        dateFrom: startOfDay(subDays(now, 90)),
        dateTo: endOfDay(now),
      };
    case RelativeValues.ThisYear:
      return {
        dateFrom: startOfDay(startOfYear(now)),
        dateTo: endOfDay(endOfYear(now)),
      };
    case RelativeValues.LastYear:
      return {
        dateFrom: startOfDay(startOfYear(subYears(now, 1))),
        dateTo: endOfDay(endOfYear(subYears(now, 1))),
      };
  }
}
